<template>
	<v-data-table
		class="elevation-1"
		:headers="headers"
		:items="value"
		:loading="loading"
		:no-data-text="$t('user.nodata.client')"
		:pagination.sync="paginationSync"
	>
		<template v-slot:items="{ item }">
			<td class="text-xs-center">
				<w-btn v-if="!item.deleted_at" color="" flat icon="edit" mini small @click="openCompanyDetails(item)" />
				<w-layout v-else row>
					<CompanyRestorer :value="item" />
					<VendorDeletor :value="item" />
				</w-layout>
			</td>
			<td v-if="item.pivot && item.pivot.client_code" :class="item.deleted_at ? 'crossed--text error--text' : ''">
				{{ item.company + ' (' + item.pivot.client_code + ')' }}
			</td>
			<td v-else :class="item.deleted_at ? 'crossed--text error--text' : ''">{{ item.company }}</td>
			<td>{{ item.details && item.details.closing_date ? $translateDateTime(item.details.closing_date, 'tiny', true) : '' }}</td>
			<td class="text-xs-center">{{ item.email }}</td>
			<td class="text-xs-center">{{ item.siret }}</td>
			<td class="text-xs-center">{{ item.naf }}</td>
		</template>
	</v-data-table>
</template>

<script>
import CustomersManagerModuleGuard from '@/mixins/ModulesGuards/CustomersManager/CustomersManagerModuleGuard'

export default {
	name: 'CustomersListSimple',
	components: {
		VendorDeletor: () => ({
			component: import('@/components/CustomersManager/CustomerDetails/Administration/VendorDeletor')
		}),
		CompanyRestorer: () => ({
			component: import('@/components/CustomersManager/CustomerDetails/Administration/CompanyRestorer')
		})
	},
	mixins: [CustomersManagerModuleGuard],
	props: {
		loading: {
			required: false,
			type: Boolean,
			default: () => false
		},
		value: {
			required: true,
			type: Array
		}
	},
	data: function () {
		return {
			headers: [
				{ align: 'center', sortable: false, text: this.$t('actions.edit') },
				{ align: 'center', text: this.$t('customer.general.company'), value: 'company' },
				{ align: 'center', text: this.$t('customer.general.closing_date'), value: 'details.closing_date' },
				{ align: 'center', text: this.$t('email'), value: 'email' },
				{ align: 'center', text: this.$t('siret'), value: 'siret' },
				{ align: 'center', text: this.$t('naf'), value: 'naf' }
			],
			paginationSync: {
				rowsPerPage: 10,
				totalItems: 10
			}
		}
	},
	methods: {
		openCompanyDetails: function (company = null) {
			this.service.openCompanyDetails(company)
		}
	}
}
</script>
